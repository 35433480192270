import {castDraft, original} from 'immer'

import type {Draft, PayloadAction} from '@reduxjs/toolkit'
import {createReducer, isAnyOf} from '@reduxjs/toolkit'
import type {ActionReducerMapBuilder} from '@reduxjs/toolkit/src/mapBuilders'
import deepEqual from 'fast-deep-equal'

import {
  compatibleAgents,
  receiveBuildAction,
  receiveBuildChanges,
  receiveBuildWSDataAction,
} from '../actions/builds'
import {removeAgentAction} from '../actions/fetch'
import * as serviceWorkerUpdateActions from '../actions/serviceWorkers'
import {buildArtifacts} from '../components/common/BuildArtifacts/BuildArtifacts.slice'
import type {TestOccurrencesCounts} from '../components/packages/Tests/Tests.types'
import {markAgentDeleted} from '../components/pages/AgentsPages/AgentsPages.actions'
import type {Normalized} from '../rest/schemata'
import type {
  BuildType,
  TestProblem,
  ProblemOccurrence,
  TestOccurrence,
  Mute,
  BuildProblem,
} from '../services/rest'
import {getIdFromLocator, restApi} from '../services/rest'
import type {
  AgentId,
  BuildId,
  BuildTypeId,
  ChangeId,
  Investigation,
  NormalizedAgentPreview,
  NormalizedAgent,
  NormalizedBuild,
  NormalizedChange,
  NormalizedProject,
  ProjectId,
  SnapshotDependenciesIDList,
  TestOccurrenceId,
  WebLinks,
  ProblemOccurrenceId,
  TestId,
  NormalizedVcsRootInstance,
  VcsRootInstanceId,
  CloudImagesHash,
  InexactEntityParameters,
  NormalizedRevision,
  UniqueRevisionId,
} from '../types'
import {toBuildId} from '../types'
import {getEmptyHash} from '../utils/empty'
import fastDeepMerge from '../utils/fastDeepMerge'
import type {KeyValue} from '../utils/object'
import {objectEntries} from '../utils/object'

import {combineReducers} from './combineReducers'
import {assignIfDeepDifferent} from './utils'

const buildAgentReducers = (
  builder: ActionReducerMapBuilder<KeyValue<AgentId, NormalizedAgent | null | undefined>>,
) => {
  builder.addMatcher(restApi.endpoints.deleteAgent.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    delete state[agentId]
  })
  builder.addMatcher(restApi.endpoints.setAgentPool.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.pool = castDraft(action.payload)
    }
  })
  builder.addMatcher(restApi.endpoints.setAuthorizedInfo.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.authorized = action.payload.status
      agent.authorizedInfo = castDraft(action.payload)
    }
  })
  builder.addMatcher(restApi.endpoints.setEnabledInfo.matchFulfilled, (state, action) => {
    const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
    const agent = state[agentId]
    if (agent != null) {
      agent.enabled = action.payload.status
      agent.enabledInfo = castDraft(action.payload)
    }
  })
}

export default combineReducers({
  agents: createReducer<KeyValue<AgentId, NormalizedAgent | null | undefined>>({}, builder => {
    builder.addCase(removeAgentAction, (state, action) => {
      delete state[action.payload]
    })
    buildAgentReducers(builder)
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.agents)
    })
  }),

  agentPreviews: createReducer<KeyValue<AgentId, NormalizedAgentPreview | null | undefined>>(
    {},
    builder => {
      builder.addCase(removeAgentAction, (state, action) => {
        delete state[action.payload]
      })
      builder.addMatcher(restApi.endpoints.deleteAgent.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        delete state[agentId]
      })
      builder.addMatcher(restApi.endpoints.setAgentPool.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.pool = castDraft(action.payload)
        }
      })
      builder.addMatcher(restApi.endpoints.setAuthorizedInfo.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.authorized = action.payload.status
        }
      })
      builder.addMatcher(restApi.endpoints.setEnabledInfo.matchFulfilled, (state, action) => {
        const agentId = Number(getIdFromLocator(action.meta.arg.originalArgs.agentLocator))
        const agent = state[agentId]
        if (agent != null) {
          agent.enabled = action.payload.status
        }
      })
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getAllAgentPreviewsNormalized.matchFulfilled,
          restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        ),
        (state, action) => action.payload.entities.agentPreviews || getEmptyHash(),
      )
    },
  ),

  agent: createReducer<KeyValue<AgentId, NormalizedAgent | null>>({}, builder => {
    builder.addCase(markAgentDeleted, (state, action) => {
      const agent = state[action.payload]
      if (agent != null) {
        agent.deleted = true
      }
    })
    builder.addCase(removeAgentAction, (state, action) => {
      delete state[action.payload]
    })
    builder.addMatcher(restApi.endpoints.getAgentNormalized.matchFulfilled, (state, action) => {
      fastDeepMerge(state, action.payload.agent.entities.agents)
    })
    buildAgentReducers(builder)
  }),

  cloudImage: createReducer<CloudImagesHash>({}, builder => {
    builder.addMatcher(restApi.endpoints.getAgentNormalized.matchFulfilled, (state, action) => {
      fastDeepMerge(state, action.payload.agent.entities.cloudImage)
    })
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      fastDeepMerge(state, action.payload.entities.cloudImage)
    })
  }),

  buildTypes: createReducer<KeyValue<BuildTypeId, BuildType>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        if (
          action.meta.arg.originalArgs.withBuildTypeDetails &&
          action.payload.entities.buildTypes
        ) {
          fastDeepMerge(state, action.payload.entities.buildTypes)
        }
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getBuildTypeNormalized.matchFulfilled,
        restApi.endpoints.getAllBuildTypesNormalized.matchFulfilled,
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
      ),
      (state, action) => {
        /**
         * Info: Performance improvement
         * Using the simplified merge method for achieving maximum performance in merging big data
         */
        fastDeepMerge(state, action.payload.entities.buildTypes)
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withBuildTypes && action.payload.entities.buildTypes) {
          fastDeepMerge(state, action.payload.entities.buildTypes)
        }
      },
    )
  }),

  buildTypeParameters: createReducer<KeyValue<BuildTypeId, InexactEntityParameters>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getBuildTypeNormalized.matchFulfilled,
        (state, action) => {
          Object.assign(state, action.payload.entities.buildTypeParameters)
        },
      )
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getProjectNormalized.matchFulfilled,
          restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
          serviceWorkerUpdateActions.getAllProjectsNormalized.match,
        ),
        (state, action) => {
          if (action.meta.arg.originalArgs.withParameters) {
            Object.assign(state, action.payload.entities.buildTypeParameters)
          }
        },
      )
    },
  ),

  buildTypeLinks: createReducer<KeyValue<BuildTypeId, WebLinks>>({}, builder => {
    const mergeBuildTypeLinks = (
      state: Draft<KeyValue<BuildTypeId, WebLinks>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => {
      Object.assign(state, action.payload.entities.buildTypeLinks)
    }
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, mergeBuildTypeLinks)
    builder.addMatcher(restApi.endpoints.getAllAgentsNormalized.matchFulfilled, (state, action) => {
      mergeBuildTypeLinks(state, action)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        receiveBuildAction,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withBuildTypes) {
          mergeBuildTypeLinks(state, action)
        }
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withLinks) {
          mergeBuildTypeLinks(state, action)
        }
      },
    )
  }),

  buildTypeDescription: createReducer({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildTypeDescription)
    })
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withDescription) {
          Object.assign(state, action.payload.entities.buildTypeDescription)
        }
      },
    )
  }),

  projects: createReducer<KeyValue<ProjectId, NormalizedProject>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        /**
         * Info: Performance improvement
         * Using the simplified merge method for achieving maximum performance in merging big data
         */
        fastDeepMerge(state, action.payload.entities.projects)
      },
    )
    builder.addMatcher(restApi.endpoints.getProjectNormalized.matchFulfilled, (state, action) => {
      if (
        action.payload.entities.projects &&
        Object.keys(action.payload.entities.projects).length > 1
      ) {
        fastDeepMerge(state, action.payload.entities.projects)
      } else {
        const projectId = action.payload.result
        const prevProject = state[projectId]
        const project = action.payload.entities.projects?.[projectId]
        if (prevProject != null) {
          assignIfDeepDifferent(prevProject, project)
        } else {
          state[projectId] = castDraft(project)
        }
      }
    })
  }),

  projectDescription: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withDescription) {
          Object.assign(state, action.payload.entities.projectDescription)
        }
      },
    )
  }),

  projectParameters: createReducer<KeyValue<ProjectId, InexactEntityParameters>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withParameters) {
          Object.assign(state, action.payload.entities.projectParameters)
        }
      },
    )
  }),

  projectLinks: createReducer<KeyValue<ProjectId, WebLinks>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withLinks) {
          Object.assign(state, action.payload.entities.projectLinks)
        }
      },
    )
  }),

  projectTemplates: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getProjectNormalized.matchFulfilled,
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.withTemplates) {
          Object.assign(state, action.payload.entities.projectTemplates)
        }
      },
    )
  }),

  overviewBuildTypes: createReducer<KeyValue<BuildTypeId, BuildType>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.overviewBuildTypes)
      },
    )
    builder.addMatcher(restApi.endpoints.getBuildTypeNormalized.matchFulfilled, (state, action) => {
      const buildTypeId = getIdFromLocator(action.meta.arg.originalArgs.btLocator)
      const buildType = action.payload.entities.buildTypes?.[buildTypeId]

      const oldBuildType = original(state)![buildTypeId]
      if (oldBuildType != null && !deepEqual(oldBuildType, buildType)) {
        state[buildTypeId] = castDraft(buildType)
      }
    })
  }),

  overviewProjects: createReducer<KeyValue<ProjectId, NormalizedProject>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllProjectsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllProjectsNormalized.match,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.overviewProjects)
      },
    )
  }),

  testOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrence>>({}, builder => {
    const mergeTestOccurrence = (
      state: Draft<KeyValue<TestOccurrenceId, TestOccurrence>>,
      testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence> | undefined,
    ) => {
      if (testOccurrences != null) {
        for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
          const count = testOccurrence?.invocations?.testCounters?.all

          if (count == null || count === 0) {
            const prevState = state[testOccurrenceId]
            if (prevState != null) {
              assignIfDeepDifferent(prevState, testOccurrence)
            } else {
              state[testOccurrenceId] = castDraft(testOccurrence)
            }
          }
        }
      }
    }
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        const testOccurrences = action.payload.data.entities.testOccurrences

        mergeTestOccurrence(state, testOccurrences)
      },
    )

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        const testOccurrences = action.payload.entities.testOccurrences

        mergeTestOccurrence(state, testOccurrences)
      },
    )
  }),

  multirunTestOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrence>>(
    {},
    builder => {
      const mergeMultirunTestOccurrences = (
        state: Draft<KeyValue<TestOccurrenceId, TestOccurrence>>,
        testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence> | undefined,
      ) => {
        if (testOccurrences != null) {
          for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
            const count = testOccurrence?.invocations?.testCounters?.all

            if (count != null && count > 0) {
              const prevState = state[testOccurrenceId]
              if (prevState != null) {
                assignIfDeepDifferent(prevState, testOccurrence)
              } else {
                state[testOccurrenceId] = castDraft(testOccurrence)
              }
            }
          }
        }
      }

      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          const testOccurrences = action.payload.data.entities.testOccurrences

          mergeMultirunTestOccurrences(state, testOccurrences)
        },
      )

      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          const testOccurrences = action.payload.entities.testOccurrences
          mergeMultirunTestOccurrences(state, testOccurrences)
        },
      )
    },
  ),

  testOccurrencesFirstFailed: createReducer<
    KeyValue<TestOccurrenceId, TestOccurrence | null | undefined>
  >({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withFirstFailed) {
          Object.assign(state, action.payload.entities.testOccurrencesFirstFailed)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withFirstFailed) {
          Object.assign(state, action.payload.data.entities.testOccurrencesFirstFailed)
        }
      },
    )
  }),

  testOccurrencesNextFixed: createReducer<
    KeyValue<TestOccurrenceId, TestOccurrence | null | undefined>
  >({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNextFixed) {
          Object.assign(state, action.payload.entities.testOccurrencesNextFixed)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withNextFixed) {
          Object.assign(state, action.payload.data.entities.testOccurrencesNextFixed)
        }
      },
    )
  }),

  testOccurrencesRunOrder: createReducer<KeyValue<TestOccurrenceId, string | null | undefined>>(
    {},
    builder => {
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withRunOrder) {
            Object.assign(state, action.payload.entities.testOccurrencesRunOrder)
          }
        },
      )
      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withRunOrder) {
            Object.assign(state, action.payload.data.entities.testOccurrencesRunOrder)
          }
        },
      )
    },
  ),

  testOccurrencesNewFailure: createReducer<KeyValue<TestOccurrenceId, boolean | null | undefined>>(
    {},
    builder => {
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withNewFailure) {
            Object.assign(state, action.payload.entities.testOccurrencesNewFailure)
          }
        },
      )
      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withNewFailure) {
            Object.assign(state, action.payload.data.entities.testOccurrencesNewFailure)
          }
        },
      )
    },
  ),

  testOccurrencesMetadataCount: createReducer<
    KeyValue<TestOccurrenceId, number | null | undefined>
  >({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMetadataCount) {
          Object.assign(state, action.payload.entities.testOccurrencesMetadataCount)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withMetadataCount) {
          Object.assign(state, action.payload.data.entities.testOccurrencesMetadataCount)
        }
      },
    )
  }),

  testOccurrencesInvestigations: createReducer<
    KeyValue<TestOccurrenceId, ReadonlyArray<Investigation>>
  >({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvestigationInfo) {
          Object.assign(state, action.payload.entities.testOccurrencesInvestigations)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvestigationInfo) {
          Object.assign(state, action.payload.data.entities.testOccurrencesInvestigations)
        }
      },
    )
  }),

  testOccurrencesCurrentlyMutes: createReducer<KeyValue<TestOccurrenceId, ReadonlyArray<Mute>>>(
    {},
    builder => {
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withMuteInfo) {
            Object.assign(state, action.payload.entities.testOccurrencesCurrentlyMutes)
          }
        },
      )
      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withMuteInfo) {
            Object.assign(state, action.payload.data.entities.testOccurrencesCurrentlyMutes)
          }
        },
      )
    },
  ),

  testOccurrencesMute: createReducer<KeyValue<TestOccurrenceId, Mute | null | undefined>>(
    {},
    builder => {
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
          restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
        ),
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withMuteInfo) {
            Object.assign(state, action.payload.entities.testOccurrencesMute)
          }
        },
      )
      builder.addMatcher(
        restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          if (action.meta.arg.originalArgs.options?.withMuteInfo) {
            Object.assign(state, action.payload.data.entities.testOccurrencesMute)
          }
        },
      )
    },
  ),

  testOccurrencesInvocationsCounters: createReducer<
    KeyValue<TestOccurrenceId, TestOccurrencesCounts>
  >({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvocationsCounters) {
          Object.assign(state, action.payload.entities.testOccurrencesInvocationsCounters)
        }
      },
    )
    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withInvocationsCounters) {
          Object.assign(state, action.payload.data.entities.testOccurrencesInvocationsCounters)
        }
      },
    )
  }),

  testProblems: createReducer<KeyValue<TestId, TestProblem>>({}, builder => {
    const mergeTestProblems = (
      state: Draft<KeyValue<TestId, TestProblem>>,
      entries: KeyValue<TestId, TestProblem> | undefined,
    ) => {
      if (entries != null) {
        for (const [testId, entry] of objectEntries(entries)) {
          const mutesCount = entry?.mutes?.count
          const investigationsCount = entry?.investigations?.count

          const prevState = state[testId]

          if (prevState != null) {
            if (!mutesCount) {
              delete prevState.mutes
            }
            if (!investigationsCount) {
              delete prevState.investigations
            }
            assignIfDeepDifferent(prevState, entry)
          } else {
            state[testId] = castDraft(entry)
          }
        }
      }
    }
    builder.addMatcher(restApi.endpoints.getAllTestProblems.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload)
    })
    builder.addMatcher(
      restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
      (state, action) => {
        const entries = action.payload.data.entities.testProblems

        mergeTestProblems(state, entries)
      },
    )
  }),

  testProblemsMutes: createReducer<KeyValue<TestId, ReadonlyArray<Mute>>>({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.data.entities.testProblemsMutes)
      },
    )
  }),

  testProblemsFailingBuildTypesCount: createReducer({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.data.entities.testProblemsFailingBuildTypesCount)
      },
    )
  }),

  testProblemsFailingBuildTypes: createReducer({}, builder => {
    builder.addMatcher(
      restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
      (state, action) => {
        Object.assign(state, action.payload.data.entities.testProblemsFailingBuildTypes)
      },
    )
  }),

  testProblemsInvestigations: createReducer<KeyValue<TestId, ReadonlyArray<Investigation>>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getAllTestProblemsNormalized.matchFulfilled,
        (state, action) => {
          Object.assign(state, action.payload.data.entities.testProblemsInvestigations)
        },
      )
    },
  ),

  testProblemTestOccurrences: createReducer<KeyValue<TestOccurrenceId, TestOccurrence>>(
    {},
    builder => {
      const mergeTestOccurrence = (
        state: Draft<KeyValue<TestOccurrenceId, TestOccurrence>>,
        testOccurrences: KeyValue<TestOccurrenceId, TestOccurrence> | undefined,
      ) => {
        if (testOccurrences != null) {
          for (const [testOccurrenceId, testOccurrence] of objectEntries(testOccurrences)) {
            const prevState = state[testOccurrenceId]
            if (prevState != null) {
              assignIfDeepDifferent(prevState, testOccurrence)
            } else {
              state[testOccurrenceId] = castDraft(testOccurrence)
            }
          }
        }
      }
      builder.addMatcher(
        restApi.endpoints.getTestProblemTestOccurrencesNormalized.matchFulfilled,
        (state, action) => {
          const testOccurrences = action.payload.data.entities.testProblemTestOccurrences

          mergeTestOccurrence(state, testOccurrences)
        },
      )
    },
  ),

  buildProblems: createReducer<KeyValue<ProblemOccurrenceId, BuildProblem>>({}, builder => {
    const mergeBuildProblens = (
      state: Draft<KeyValue<ProblemOccurrenceId, BuildProblem>>,
      buildProblem: KeyValue<ProblemOccurrenceId, BuildProblem> | undefined,
    ) => {
      if (buildProblem != null) {
        for (const [problemOccurrenceId, problem] of objectEntries(buildProblem)) {
          const prevState = state[problemOccurrenceId]
          if (prevState != null) {
            assignIfDeepDifferent(prevState, problem)
          } else {
            state[problemOccurrenceId] = castDraft(problem)
          }
        }
      }
    }
    builder.addMatcher(restApi.endpoints.getBuildProblemsTree.matchFulfilled, (state, action) => {
      const buildProblems = action.payload.entities.buildProblems

      mergeBuildProblens(state, buildProblems)
    })
  }),

  buildProblemsMutes: createReducer<KeyValue<ProblemOccurrenceId, ReadonlyArray<Mute>>>(
    {},
    builder => {
      builder.addMatcher(restApi.endpoints.getBuildProblemsTree.matchFulfilled, (state, action) => {
        Object.assign(state, action.payload.entities.buildProblemsMutes)
      })
    },
  ),

  buildProblemsInvestigations: createReducer<
    KeyValue<ProblemOccurrenceId, ReadonlyArray<Investigation>>
  >({}, builder => {
    builder.addMatcher(restApi.endpoints.getBuildProblemsTree.matchFulfilled, (state, action) => {
      Object.assign(state, action.payload.entities.buildProblemsInvestigations)
    })
  }),

  problemOccurrences: createReducer<KeyValue<ProblemOccurrenceId, ProblemOccurrence>>(
    {},
    builder => {
      builder.addMatcher(
        restApi.endpoints.getBuildProblemOccurrence.matchFulfilled,
        (state, action) => {
          state[action.payload.id!] = castDraft(action.payload)
        },
      )
      builder.addMatcher(
        restApi.endpoints.getProblemOccurrenceTree.matchFulfilled,
        (state, action) => {
          Object.assign(state, action.payload.entities.problemOccurrences)
        },
      )
    },
  ),

  buildArtifacts: buildArtifacts.reducer,

  compatibleAgents: compatibleAgents.reducer,

  builds: createReducer<KeyValue<BuildId, NormalizedBuild | null>>({}, builder => {
    function mergeBuilds(
      state: Draft<KeyValue<BuildId, NormalizedBuild | null>>,
      builds: KeyValue<BuildId, NormalizedBuild | null> | undefined,
    ) {
      /**
       * Info: Performance improvement
       * Using the simplified merge method for achieving maximum performance in merging big data
       */
      fastDeepMerge(state, builds)

      for (const build of Object.values(state)) {
        if (build?.state === 'finished') {
          build['running-info'] = undefined
        }
      }
    }

    const mergeBuildsFromEntities = (
      state: Draft<KeyValue<BuildId, NormalizedBuild | null>>,
      action: PayloadAction<Normalized<unknown>>,
    ) => mergeBuilds(state, action.payload.entities.builds)

    builder.addCase(receiveBuildWSDataAction, (state, action) => mergeBuilds(state, action.payload))

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getTestOccurrenceNormalized.matchFulfilled,
        restApi.endpoints.getTestOccurrenceTree.matchFulfilled,
      ),
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withBuildInfo) {
          mergeBuilds(state, action.payload.entities.builds)
        }
      },
    )

    builder.addMatcher(
      restApi.endpoints.getAllTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        if (action.meta.arg.originalArgs.options?.withBuildInfo) {
          mergeBuilds(state, action.payload.data.entities.builds)
        }
      },
    )

    builder.addMatcher(
      restApi.endpoints.getTestProblemTestOccurrencesNormalized.matchFulfilled,
      (state, action) => {
        mergeBuilds(state, action.payload.data.entities.builds)
      },
    )

    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBranchesOfBuildTypeMerged.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction,
        restApi.endpoints.setMultipleBuildComments.matchFulfilled,
        restApi.endpoints.addTagsToMultipleBuilds.matchFulfilled,
        restApi.endpoints.pinMultipleBuilds.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      mergeBuildsFromEntities,
    )

    builder.addMatcher(restApi.endpoints.getChangeLog.matchFulfilled, (state, action) =>
      mergeBuilds(state, action.payload.builds?.entities.builds),
    )

    builder.addMatcher(restApi.endpoints.setBuildTags.matchPending, (state, action) => {
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.meta.arg.originalArgs.tags)
      }
    })

    builder.addMatcher(restApi.endpoints.setBuildTags.matchFulfilled, (state, action) => {
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.payload)
      }
    })

    builder.addMatcher(restApi.endpoints.setBuildTags.matchRejected, (state, action) => {
      if (action.meta.condition) {
        // request was aborted due to condition (another query already running)
        return
      }
      const build = state[toBuildId(getIdFromLocator(action.meta.arg.originalArgs.buildLocator))]
      if (build != null) {
        build.tags = castDraft(action.meta.arg.originalArgs.prevTags)
      }
    })
  }),

  buildsTestOccurrencesCount: createReducer<KeyValue<BuildId, number | undefined>>({}, builder => {
    builder.addCase(receiveBuildWSDataAction, (state, action) => {
      for (const [id, build] of Object.entries(action.payload)) {
        const buildId = toBuildId(id)
        const count = build?.testOccurrences?.count
        if (count != null) {
          state[buildId] = count
        } else {
          delete state[buildId]
        }
      }
    })
  }),

  changes: createReducer<KeyValue<ChangeId, NormalizedChange>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        receiveBuildChanges,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
      ),
      (state, action) => {
        fastDeepMerge(state, action.payload.entities.changes)
      },
    )
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        fastDeepMerge(state, action.payload.changes?.entities.changes)
      },
    )
  }),

  modificationsOfChanges: createReducer<KeyValue<ChangeId, NormalizedChange>>(
    getEmptyHash(),
    builder => {
      builder.addMatcher(
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        (state, action) => {
          Object.assign(state, action.payload.entities.modificationsOfChanges)
        },
      )
    },
  ),

  revisions: createReducer<KeyValue<UniqueRevisionId, NormalizedRevision>>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.revisions)
      },
    )
  }),

  vcsLabels: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        receiveBuildChanges.match,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.vcsLabels)
      },
    )
  }),

  vcsRootInstances: createReducer<KeyValue<VcsRootInstanceId, NormalizedVcsRootInstance>>(
    {},
    builder => {
      builder.addMatcher(
        isAnyOf(
          restApi.endpoints.getAllChanges.matchFulfilled,
          restApi.endpoints.getChangeLog.matchFulfilled,
        ),
        (state, action) => {
          Object.assign(state, action.payload.changes?.entities.vcsRootInstances)
        },
      )
      builder.addMatcher(
        isAnyOf(
          receiveBuildChanges.match,
          restApi.endpoints.getBuildNormalized.matchFulfilled,
          restApi.endpoints.getModificationsOfChange.matchFulfilled,
          restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
          serviceWorkerUpdateActions.getAllBuildsNormalized.match,
        ),
        (state, action) => {
          Object.assign(state, action.payload.entities.vcsRootInstances)
        },
      )
    },
  ),

  vcsRoots: createReducer({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllChanges.matchFulfilled,
        restApi.endpoints.getChangeLog.matchFulfilled,
      ),
      (state, action) => {
        Object.assign(state, action.payload.changes?.entities.vcsRoots)
      },
    )
    builder.addMatcher(
      isAnyOf(
        receiveBuildChanges.match,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getModificationsOfChange.matchFulfilled,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        Object.assign(state, action.payload.entities.vcsRoots)
      },
    )
  }),

  snapshotDependencies: createReducer<SnapshotDependenciesIDList>({}, builder => {
    builder.addMatcher(
      isAnyOf(
        restApi.endpoints.getAllAgentsNormalized.matchFulfilled,
        restApi.endpoints.getAllBranchesOfBuildTypeMerged.matchFulfilled,
        restApi.endpoints.getBuildNormalized.matchFulfilled,
        restApi.endpoints.getBuildNormalizedAsList.matchFulfilled,
        receiveBuildAction.match,
        restApi.endpoints.getAllBuildsNormalized.matchFulfilled,
        serviceWorkerUpdateActions.getAllBuildsNormalized.match,
      ),
      (state, action) => {
        const builds = action.payload.entities.builds

        if (!builds) {
          return
        }

        for (const [buildId, build] of objectEntries(builds)) {
          const dependencies = build?.['snapshot-dependencies']?.build

          if (dependencies != null) {
            state[toBuildId(buildId!)] = dependencies.map(dependency => dependency.id!)
          }
        }
      },
    )
  }),
})
