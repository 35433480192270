import type {EntryPoint} from 'react-relay'
import type {LoaderFunctionArgs} from 'react-router'
import {getRequest} from 'relay-runtime'

import {OverviewCloudImageTabNamesEnum, toAgentTypeId} from '../../../../types'
import type {ThinNestedEntryPointParamsObject} from '../../../../types/entryPoints'
import {makeResource} from '../../../../utils/makeResource'
import {queryToObject} from '../../../../utils/queryParams'

import type {AgentTypePageContainerComponent, NestedEntryPoints} from './AgentTypePage'
import {query} from './AgentTypePage.queries'
import {AgentTypeParametersEntryPoint} from './AgentTypeParameters/AgentTypeParameters.entryPoint'
import {isNewCloudImageParametersTab} from './AgentTypeParameters/utils/isNewCloudImageParametersTab'

export const AgentTypePageEntryPoint: EntryPoint<
  AgentTypePageContainerComponent,
  LoaderFunctionArgs
> = {
  root: makeResource(
    'AgentTypePage',
    () => import(/* webpackChunkName: "AgentTypePage" */ './AgentTypePage'),
  ),
  getPreloadProps: ({params, request}) => {
    const entryPoints: ThinNestedEntryPointParamsObject<NestedEntryPoints> = {}
    const url = new URL(request.url)
    const {tab} = queryToObject(url.search)
    if (tab === OverviewCloudImageTabNamesEnum.AGENT_PARAMETERS && isNewCloudImageParametersTab()) {
      entryPoints.parametersTab = {
        entryPoint: AgentTypeParametersEntryPoint,
        entryPointParams: toAgentTypeId(params.agentTypeId!),
      }
    }
    return {
      queries: {
        main: {
          parameters: getRequest(query),
          variables: {locator: `id:${params.agentTypeId}`},
          options: {networkCacheConfig: {metadata: {essential: true}}},
        },
      },
      entryPoints,
    }
  },
}
