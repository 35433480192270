import buildType20pxIcon from '@jetbrains/icons/buildType-20px.svg'
import buildTypeComposite20pxIcon from '@jetbrains/icons/buildType-composite-20px.svg'
import buildTypeCompositeError20pxIcon from '@jetbrains/icons/buildType-composite-error-20px.svg'
import buildTypeCompositeErrorIcon from '@jetbrains/icons/buildType-composite-error.svg'
import buildTypeCompositeIcon from '@jetbrains/icons/buildType-composite.svg'
import buildTypeError20pxIcon from '@jetbrains/icons/buildType-error-20px.svg'
import buildTypeErrorIcon from '@jetbrains/icons/buildType-error.svg'
import buildTypeIcon from '@jetbrains/icons/buildType.svg'
import project20pxIcon from '@jetbrains/icons/project-20px.svg'
import projectError20pxIcon from '@jetbrains/icons/project-error-20px.svg'
import projectErrorIcon from '@jetbrains/icons/project-error.svg'
import projectIcon from '@jetbrains/icons/project.svg'
import template20pxIcon from '@jetbrains/icons/template-20px.svg'
import templateIcon from '@jetbrains/icons/template.svg'
import type {IconType as Icon} from '@jetbrains/ring-ui/components/icon/icon'
import classNames from 'classnames'
import * as React from 'react'

import type {KeyValue} from '../../../utils/object'
import SvgIcon from '../SvgIcon/SvgIcon'

import {Sizes, EntityIconType} from './ProjectOrBuildTypeIcon.types'
import type {DefaultProps, Props} from './ProjectOrBuildTypeIcon.types'

import styles from './ProjectOrBuildTypeIcon.css'

const Icons: KeyValue<string, Icon> = {
  'buildType-error': buildTypeErrorIcon,
  buildType: buildTypeIcon,
  'buildType-error-20px': buildTypeError20pxIcon,
  'buildType-20px': buildType20pxIcon,
  'buildType-composite-error': buildTypeCompositeErrorIcon,
  'buildType-composite': buildTypeCompositeIcon,
  'buildType-composite-error-20px': buildTypeCompositeError20pxIcon,
  'buildType-composite-20px': buildTypeComposite20pxIcon,
  'project-error': projectErrorIcon,
  project: projectIcon,
  'project-error-20px': projectError20pxIcon,
  'project-20px': project20pxIcon,
  template: templateIcon,
  'template-20px': template20pxIcon,
}

const getIcon = (name: string) => Icons[name] ?? name

const primaryTypes = new Set<EntityIconType>([EntityIconType.PROJECT, EntityIconType.BUILD_TYPE])

class ProjectOrBuildTypeIcon extends React.PureComponent<Props> {
  static defaultProps: DefaultProps = {
    type: EntityIconType.PROJECT,
    size: 'M',
  }

  render(): React.ReactNode {
    const {type, composite, status, size, className, title, paused} = this.props

    if (type == null) {
      return null
    }

    const typeIsInvestigation = type === EntityIconType.INVESTIGATION
    const typeIsPrimary = primaryTypes.has(type)
    const classes = classNames(
      styles.icon,
      className,
      (typeIsPrimary || typeIsInvestigation) && status && styles[status],
      {[styles.iconL]: size === 'L', [styles.paused]: paused},
    )
    const name = [
      type,
      type === 'buildType' && composite === true && 'composite',
      typeIsPrimary && status === 'failed' && 'error',
      paused && !typeIsInvestigation && 'paused',
      size === 'M' ? null : `${Sizes[size]}px`,
    ]
      .filter(Boolean)
      .join('-')
    return <SvgIcon className={classes} icon={getIcon(name)} title={title} />
  }
}

export default ProjectOrBuildTypeIcon
