import type {EntryPoint} from 'react-relay'
import {getRequest} from 'relay-runtime'

import {makeResource} from '../../../utils/makeResource'

import type {MatrixParamBuildComponent} from './MatrixParamBuild'
import {query} from './MatrixParamBuild.queries'
import type {MatrixParamBuildProps} from './MatrixParamBuild.types'

export const MatrixParamBuildEntryPoint: EntryPoint<
  MatrixParamBuildComponent,
  MatrixParamBuildProps
> = {
  root: makeResource(
    'MatrixParamBuild',
    () => import(/* webpackChunkName: "MatrixParamBuild" */ './MatrixParamBuild'),
  ),
  getPreloadProps: ({buildTypeId, featureId}) => ({
    queries: {
      main: {
        parameters: getRequest(query),
        variables:
          featureId != null
            ? {btLocator: `id:${buildTypeId}`, featureId, skip: false}
            : {btLocator: '', featureId: '', skip: true},
      },
    },
  }),
}
