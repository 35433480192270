import Theme, {ThemeProvider} from '@jetbrains/ring-ui/components/global/theme'
import * as React from 'react'
import {useDeferredValue, useLayoutEffect, useSyncExternalStore} from 'react'

import type {HeaderProps} from './Header'

import styles from './Header.css'

const Header = React.lazy(
  () =>
    import(
      /* webpackChunkName: "Header" */
      './Header'
    ),
)

const HeaderLazy = (props: HeaderProps) => {
  const scrollLeft = useSyncExternalStore(
    onStoreChange => {
      document.addEventListener('scroll', onStoreChange)
      return () => document.removeEventListener('scroll', onStoreChange)
    },
    () => document.documentElement.scrollLeft,
  )
  const deferredScrollLeft = useDeferredValue(scrollLeft)
  useLayoutEffect(() => {
    const placeholder = document.getElementById('react-header-placeholder')
    if (!placeholder) {
      return undefined
    }
    placeholder.hidden = true
    return () => {
      placeholder.hidden = false
    }
  }, [])

  return React.useMemo(
    () => (
      <ThemeProvider
        theme={Theme.DARK}
        className={styles.container}
        style={{left: -deferredScrollLeft}}
      >
        <React.Suspense fallback={<div data-suspense-fallback />}>
          <Header {...props} />
        </React.Suspense>
      </ThemeProvider>
    ),
    [props, deferredScrollLeft],
  )
}

export default React.memo(HeaderLazy)
